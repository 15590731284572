<template>
    <Component
        :is="element"
        :link="illustration?.linkField || null"
        class="illustration-item"
    >
        <div class="illustration-item__image">
            <div class="illustration-item__sticker">
                <template v-if="$slots.offer">
                    <h3><slot name="offer" /></h3>
                    <img src="~/assets/img/textures/sticker.svg" alt="">
                </template>
            </div>
            <BaseImage
                v-if="illustration?.image?.[0]"
                :image="illustration?.image?.[0]"
                :alt="illustration?.image?.[0]?.title"
                sizes="(min-width: 900px) 95vw, 600px"
            />
        </div>

        <div
            v-if="!!$slots.title || pending"
            class="illustration-item__title"
        >
            <template v-if="pending">
                <h3>
                    <Skeletor
                        :shimmer="false"
                        width="80%"
                    />
                </h3>
            </template>
            <template v-else>
                <h3><slot name="title" /></h3>
            </template>
        </div>

        <div
            v-if="!!$slots.description || pending"
            class="illustration-item__description"
        >
            <template v-if="pending">
                <div>
                    <Skeletor
                        :shimmer="false"
                        width="80%"
                    />
                    <Skeletor
                        :shimmer="false"
                    />
                    <Skeletor
                        :shimmer="false"
                        width="35%"
                    />
                </div>
            </template>
            <template v-else>
                <slot name="description" />
            </template>
        </div>

        <div
            v-if="!!$slots.cta || pending"
            class="illustration-item__cta"
        >
            <template v-if="pending">
                <Skeletor
                    :shimmer="false"
                    width="50%"
                />
            </template>
            <template v-else>
                <BaseButton
                    v-if="illustration?.linkField?.url"
                    :to="illustration?.linkField"
                    :icon="illustration?.linkField?.icon"
                    class="base-button--tertiary"
                >
                    <slot name="cta" />
                    <BaseIcon icon="arrow" />
                </BaseButton>
            </template>
        </div>
    </Component>
</template>

<script setup>
const props = defineProps({
    illustration: {
        type: Object,
        required: false,
        default: () => {}
    },
    item: {
        type: Object,
        required: false,
        default: () => {}
    }
});

const element = computed(() => {
    return props.illustration?.linkField ? resolveComponent('CraftLink') : 'div';
});
</script>

<style lang="less">
.illustration-item {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2xs);
    width: 16rem;
    height: 100%;
    margin: 0 auto;
    align-content: center;
    text-align: center;

    @media @q-md-min {
        width: 100%;
        gap: var(--spacing-sm);
    }

    @media @q-lg-min {
        grid-column: span 4;
    }
}

a.illustration-item {
    justify-content: center;
    align-items: center;
    text-decoration: none;

    transition: background 0.3s ease;

    &:hover {
        text-decoration: none;

        .dn-button {
            border-color: var(--color-darkest);
            background-color: var(--color-tertiary-dark);
            color: var(--color-lightest);
        }
    }
}

.illustration-item__image {
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;

    figure {
        height: 100%;
    }

    picture {
        display: block;
        height: 100%;
    }

    img {
        .img-responsive();

        height: 100%;
        object-fit: cover;
    }

    @media @q-md-min {
        padding: 0 var(--spacing-lg);
        aspect-ratio: 6 / 5;
    }
}

.illustration-item__sticker {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;

    h3 {
        position: absolute;
        width: 5.125rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(10deg);
        text-align: center;
        hyphens: auto;
        text-wrap: balance;
        z-index: 10;
    }

    img {
        animation: rotation 16s linear infinite;
    }

    @media @q-md-min {
        right: var(--spacing-lg);
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.illustration-item__title {
    .typography-label();
    text-align: center;
    margin-top: auto;
}

.illustration-item__description {
    .typography-text();
    text-align: center;
}

.sticker__title--small {
    .illustration-item__sticker {
        h3 {
            font-size: var(--fontSize-2xl);
            top: 45%;
        }
    }
}
</style>
