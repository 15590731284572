<template>
    <BaseSection class="base-section--side-gap">
        <template
            v-if="pending || !!$slots.title"
            #title
        >
            <template v-if="pending">
                <Skeletor />
            </template>
            <slot
                v-else
                name="title"
            />
        </template>

        <template
            v-if="pending || !!$slots.description"
            #intro
        >
            <template v-if="pending">
                <Skeletor />
            </template>
            <slot
                v-else
                name="description"
            />
        </template>

        <div
            class="illustration-grid"
            v-bind="$attrs"
            :class="getModifier"
        >
            <slot />
        </div>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    pending: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        required: false,
        default: null
    },
    description: {
        type: String,
        required: false,
        default: null
    },
    items: {
        type: Array,
        required: false,
        default: () => []
    }
});

// Modifier tells us how many more than (x * 3) items we have
const getModifier = computed(() => {
    const classList = [];
    const itemsValue = toValue(props.items);
    let modulo = 0;

    if (itemsValue.length === 4) {
        // Special edge case for 4 items
        if (!classList.includes('grid-container--modulo-lg-1-4-items')) {
            classList.push('grid-container--modulo-lg-1-4-items');
        }
    } else {
        modulo = itemsValue.length % 3;
        if (!classList.includes(`grid-container--modulo-lg-${modulo}`)) {
            classList.push(`grid-container--modulo-lg-${modulo}`);
        }
    }

    modulo = itemsValue.length % 2;
    if (!classList.includes(`grid-container--modulo-md-${modulo}`)) {
        classList.push(`grid-container--modulo-md-${modulo}`);
    }

    return classList;
});
</script>

<style lang="less">
.illustration-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);

    @media @q-md-min {
        grid-template-columns: repeat(4, 1fr);
        align-items: start;
        gap: var(--spacing-xl) 5.1875rem;
    }

    @media @q-lg-min {
        grid-template-columns: repeat(12, 1fr);
        align-items: start;
    }
}

@media @q-md {
    .illustration-item {
        grid-column: span 2;
    }

    .grid-container--modulo-md-1 {
        .illustration-item:nth-last-child(1) {
            grid-column: 2 / span 2;
        }
    }
}

@media @q-lg-min {
    .grid-container--modulo-lg-2 {
        .illustration-item:nth-last-child(2) {
            grid-column: 3 / span 4;
        }
    }

    .grid-container--modulo-lg-1 {
        .illustration-item:nth-last-child(1) {
            grid-column: 5 / span 4;
        }
    }

    .grid-container--modulo-lg-1-4-items{
        .illustration-item:nth-child(1),
        .illustration-item:nth-last-child(2) {
            grid-column: 3 / span 4;
        }
    }
}
</style>
